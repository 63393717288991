const content = {
    en: {
        "about": "About us",
        "portfolio": "Our work",
        "contact": "Contact",
        "terms": "Funding",
        "legal": "Legal",
        "privacy": "Privacy"
    },
    hu: {
        "about": "Rólunk",
        "portfolio": "Munkáink",
        "contact": "Kapcsolat",
        "terms": "Támogatási információk",
        "legal": "Impresszum",
        "privacy": "Adatbiztonság"
    }
}

export default content;