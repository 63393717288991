import React from "react";
import "../../styles/components/plane.css";

function Plane({ image, type }) {
    const includeButton = () => type === "button" ? (<div className="card-body">
        <a href="/" className="btn">Go somewhere</a>
    </div>) : "";
    return (
        <section className="card plane container-fluid align-items-left mb-4">
            <img src={`/images/${image}.png`} className="card-img-left" style={{ maxHeight: "20%" }} alt="..." />
            {includeButton()}
        </section>
    );
}

export default Plane;