const content = {
    en: [
        {
            title: "Electrical Engineering",
            text: ["Our services provide comprehensive electrical solutions for industrial complexes and specialized machinery, covering design, installation, documentation, testing, and commissioning.[line divide]Our experienced design team creates meticulous documentation, meeting *strict *ATEX *standards while guiding construction teams.[line divide]We manufacture high-quality control cabinets at our facility, sourcing components from reputable European manufacturers.[line divide]As an *accredited *Siemens *system *integrator, we prioritize Siemens products and integrate top-tier components from brands like Rittal, Illinox, Schneider Electric, Phoenix Contact, and Weidmüller.[line divide]Our tailored approach to electrical construction ensures compliance with client needs and industry standards through long-standing partnerships.[line divide]Our portfolio includes projects delivered within the EU and beyond, serving clients in *food, *pharmaceutical, *chemical, and *technology sectors."],
        },
        {
            title: "Industrial Automation",
            text: ["With over *20 *years of experience, we specialize in managing diverse technology-driven challenges, boasting a wide project portfolio.[line divide]Our focus lies predominantly in the food and pharmaceutical sectors, although our expertise extends to various industrial automation projects, catering to both continuous operation and batch-based production systems.[line divide]Proficient in *Rockwell and *Omron technologies, we tailor automation systems meticulously to our clients' needs.[line divide]Our capabilities span beyond greenfield projects; we excel in modernizing existing infrastructures, upgrading outdated control systems like *SattControl and legacy *Siemens *S7-300-based PLCs.[line divide]Embracing the era of Industry 4.0, we prioritize continuous technological advancements, collaborating with our dedicated team and partners to develop state-of-the-art technical control systems.[line divide]Our solutions prioritize long-term data storage, sophisticated data processing and analysis, comprehensive monitoring capabilities, intuitive operator interfaces, and robust support for management decision-making processes, ensuring alignment with industry demands."],
        },
        {
            title: "OT Network Engineering",
            text: ["In today's complex IT-OT landscape, meticulous attention to network planning and hardware selection is essential to meet industry standards.[line divide]Cybersecurity management, extending to OT networks, adds another layer of challenge.[line divide]Leveraging Siemens' solutions, we specialize in designing and deploying *OT networks, from simple configurations to advanced optical setups.[line divide]Additionally, we offer support for *VPN-based remote access solutions, crucial for maintaining production integrity.[line divide]With expertise in EU's *NIS2 directive, we assist clients in navigating regulatory complexities, ensuring compliance and operational resilience."],
        },
        {
            title: "Data Collection & Processing",
            text: ["With the rise of industrial automation, data availability and utility have grown significantly.[line divide]Modern *SCADA systems not only track processes historically but also store and process events and alarms in *SQL databases.[line divide]Our expertise lies in creating advanced data collection systems managing key performance indicators like OEE and energy consumption for production lines and machines.[line divide]Integrating *OEE *metrics with MES provides crucial insights for production planning.[line divide]Our energy management solutions offer precise measurement, contributing to environmental objectives and regulatory compliance."],
        },
        {
            title: "Repair & Maintenance",
            text: ["The electrical industry has advanced significantly, resulting in *minimal *component *failure *rates.[line divide]Regular inspection and maintenance remain crucial for uninterrupted operation.[line divide]We offer customizable maintenance contracts and remote software support.[line divide]Our services include training local maintenance personnel for quick on-site issue resolution, minimizing downtime and optimizing system performance."],
        }
    ],
    hu: [
        {
            title: "Villamos tervezés",
            text: ["Szolgáltatásunk kiterjed ipari *komplex *termelési *rendszerek és célgépek villamos tervezésére és beüzemelésére, magában foglalva a teljeskörű dokumentálást és tesztelést.[line divide]Többéves tapasztalattal rendelkező tervező kollégáink az ipari követelményeket és innovációt figyelembe véve vezetik és támogatják a kivitelező csapatokat.[line divide]Tervdokumentációt akár *ATEX *szabványok szerint is készítünk.[line divide]Gyenge- és erősáramú kapcsolószekrények gyártását saját telephelyünkön végezzük.[line divide]Akkreditált *Siemens *rendszerintegrátorként előnyben részesítjük a Siemens termékeket és olyan márkák csúcskategóriás komponenseit, mint a Rittal, Illinox, Schneider Electric, Phoenix Contact és Weidmüller.[line divide]Portfóliónk az EU-n belüli és azon kívüli projekteket is magában foglal. Megbízóink jelentős része *élelmiszer-, *gyógyszer- és *vegyipari nagyvállalat vagy technológiai beszállító."],
        },
        {
            title: "Ipari automatizálás",
            text: ["Több mint *20 *éves *tapasztalattal és széles projektportfólióval büszkélkedünk.[line divide]Megbízásaink zöme élelmiszer- és gyógyszeripari fejlesztés, azonban rendelkezünk számos egyéb iparági referenciával folyamatos és batch alapú termelőrendszerek beüzemelése terén.[line divide]Szerződött Siemens-partnerként túlnyomórészt *Siemens *S7-1500 *PLC és Siemens *WinCC *SCADA alapú irányítástechnikai rendszereket fejlesztünk és telepítünk.[line divide]Igény szerint *Rockwell és *Omron alapú rendszerek fejlesztésében is partnerek vagyunk.[line divide]A zöld mezős beruházások mellett a jelenleg üzemelő, több tíz éves SattControl és régebbi Siemens S7-300 alapú PLC kiváltásában is nagy tapasztalattal rendelkezünk."],
        },
        {
            title: "OT hálózat-tervezés",
            text: ["Ipari sztenderdek alapján, többnyire a *Siemens gyári megoldásait alkalmazva segítünk megbízóinknak az OT hálózatok tervezésében és kivitelezésében.[line divide]Projektportfóliónk az egyszerű csillagpontos topológiáktól az *összetettebb *optikai *hálózatokig is kiterjed.[line divide]Segítséget tudunk továbbá nyújtani a Siemens gyári alkalmazásait használva *VPN-alapú *távelérések kiépítésében, mely a termelés üzembiztonságának elengedhetetlen feltétele.[line divide]Az uniós *NIS2 *irányelv kapcsán és egyéb szabályozási kérdésben szakértelemmel segítjük ügyfeleinket az eligazodásban, biztosítjuk a szabályozásoknak való megfelelést és a működési rugalmasságot."],
        },
        {
            title: "Adatgyűjtés és feldolgozás",
            text: ["Modern *SCADA rendszereink a gyártó üzemekben rögzítik a termelési eseményeket, trendeket állapítanak meg és szükség esetén riasztásokat is eszközölnek.[line divide]Számos referenciával rendelkezünk *komplex *adatgyűjtő *rendszerek fejlesztésében és telepítésében. Ezek a rendszerek teljes gyártósorok és azok gépegységeinek *termelési *adatait (OEE, Overall Equipment Effectiveness) és energiafelhasználási mutatóit képesek kezelni.[line divide]Az OEE adatokhoz kapcsolt *MES *rendszereink (Manufacturing Execution System) kulcsfontosságú támogatást nyújtanak a termeléstervezők és döntéshozók számára. [line divide]*Energiaszabályzó rendszereink a felhasznált energiát mérik és monitorálják, ezáltal megkönnyítve a zöld átállást és a jogszabályoknak való megfelelést."],
        },
        {
            title: "Szerviz és karbantartás",
            text: ["Az elektromos ipar jelentős fejlődésen ment keresztül, amely *minimális *alkatrészmeghibásodási *arányt eredményezett. Ennek ellenére a rendszeres ellenőrzés és karbantartás továbbra is kulcsfontosságú a zavartalan működéshez.[line divide]*Testreszabható *karbantartási *szerződéseket és távoli szoftveres támogatást is kínálunk.[line divide]Szolgáltatásaink közé tartozik a helyi karbantartó személyzet képzése, a probléma gyors helyszíni megoldása, az állásidő minimalizálása, és a rendszer teljesítményének optimalizálása."],
        }
    ]
}

export default content;