import React from 'react';
import BaseComponent from '../BaseComponent';
import "../../styles/components/plane.css";

function GDPR() {
  return (
    <>
      <BaseComponent language="hu">
        <section className="container-fluid d-flex flex-column p-3" style={{ width: "70%" }}>

          <div className="d-flex flex-column align-items-center p-5">
            <p className="align-text-center" style={{ fontSize: "1.5em" }}>Belső/Internal GDPR</p>
            <a className="plane mx-5" target="_blank" href="/legal/GDPR_internal_2020.pdf">PDF Dokumentum/Document</a>
          </div>

          <div className="d-flex flex-column align-items-center p-5">
            <p className="align-text-center" style={{ fontSize: "1.5em" }}>Külső/External GDPR</p>
            <a className="plane mx-5" target="_blank" href="/legal/GDPR_external_2020.pdf">PDF Dokumentum/Document</a>
          </div>

        </section>
      </BaseComponent>
    </>
  );
}

export default GDPR;